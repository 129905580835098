import React, { useState, useEffect, useContext } from 'react'
import '../Style/LoginForm.css'
import { useCookies } from 'react-cookie'
import { useNavigate } from 'react-router-dom'
import { TextField, Button, Box, Container, Typography } from '@mui/material'
import { create } from '@mui/material/styles/createTransitions'
import Base from '../Components/Base'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone'

const CreationForm = () => {
  const [cookies] = useCookies(['token'])
  const [formValues, setFormValues] = useState({
    //   title: '',
    //   author: '',
    //   summary: '',
    //   publisher: '',
    //   isbn_10: '',
    //   isbn_13: '',
    //   // pages: '',
    publish_date: dayjs().format('YYYY-MM-DD'),
    started_date: dayjs().format('YYYY-MM-DD'),
    ended_date: dayjs().format('YYYY-MM-DD'),
    //   // note_5: '',
    //   personnal_note: '',
    //   image_url: '',
  })
  const apiUrl = process.env.REACT_APP_API_URL
  const [error, setError] = useState(false)
  const navigate = useNavigate()

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormValues({
      ...formValues,
      [name]: value,
    })
  }

  function create_book(event) {
    event.preventDefault()
    const xhr = new XMLHttpRequest()
    xhr.open('POST', `${apiUrl}/books/create`)
    xhr.setRequestHeader('Authorization', 'Bearer ' + cookies.token)
    xhr.setRequestHeader('Content-Type', 'application/json')
    xhr.onload = function () {
      if (xhr.status === 200) {
        navigate('/')
      } else {
        setError(true)
      }
    }
    xhr.send(JSON.stringify(formValues))
  }

  return (
    <Base>
      <Container maxWidth="sm">
        <Typography variant="h4" component="div" align="center">
          Ajouter un livre
        </Typography>

        <Box
          component="form"
          onSubmit={create_book}
          // noValidate
          autoComplete="off"
          sx={{ mt: 4 }}
        >
          <TextField
            fullWidth
            label="Titre"
            variant="filled"
            margin="normal"
            name="title"
            onChange={handleChange}
            required
          />
          <TextField
            fullWidth
            label="Auteur"
            variant="filled"
            margin="normal"
            name="author"
            onChange={handleChange}
          />
          <TextField
            fullWidth
            label="Édition"
            variant="filled"
            margin="normal"
            name="publisher"
            onChange={handleChange}
          />

          <DatePicker
            label="Date de publication"
            defaultValue={dayjs()}
            onChange={(newValue) =>
              (formValues.publish_date = dayjs(newValue).format('YYYY-MM-DD'))
            }
            name="publish_date"
            slotProps={{
              textField: {
                variant: 'filled',
                fullWidth: true,
                sx: { mt: 2, mb: 1 },
              },
            }}
          />
          <TextField
            fullWidth
            label="Pages"
            variant="filled"
            margin="normal"
            name="pages"
            onChange={handleChange}
            type="number"
          />
          <TextField
            fullWidth
            label="Résumé"
            variant="filled"
            margin="normal"
            name="summary"
            onChange={handleChange}
            multiline
            rows={4}
          />
          <TextField
            fullWidth
            label="Note"
            variant="filled"
            margin="normal"
            name="note_5"
            onChange={handleChange}
            type="number"
          />
          <TextField
            fullWidth
            label="Note personnel"
            variant="filled"
            margin="normal"
            name="personnal_note"
            onChange={handleChange}
            multiline
            rows={4}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              justifyContent: 'space-between',
              mt: 2,
              mb: 1,
            }}
          >
            <DatePicker
              label="Date de début"
              defaultValue={dayjs()}
              onChange={(newValue) =>
                (formValues.started_date = dayjs(newValue).format('YYYY-MM-DD'))
              }
              name="started_date"
              slotProps={{
                textField: {
                  variant: 'filled',
                  fullWidth: true,
                  sx: { mr: { xs: 0, sm: 2 } },
                },
              }}
            />
            <DatePicker
              label="Date de fin"
              defaultValue={dayjs()}
              name="ended_date"
              onChange={(newValue) =>
                (formValues.ended_date = dayjs(newValue).format('YYYY-MM-DD'))
              }
              slotProps={{
                textField: {
                  variant: 'filled',
                  fullWidth: true,
                  sx: { ml: { xs: 0, sm: 2 }, mt: { xs: 3, sm: 0 } },
                },
              }}
            />
          </Box>

          <TextField
            fullWidth
            label="Image URL"
            variant="filled"
            margin="normal"
            name="image_url"
            onChange={handleChange}
          />
          <TextField
            fullWidth
            label="ISBN 10"
            variant="filled"
            margin="normal"
            name="isbn_10"
            onChange={handleChange}
          />
          <TextField
            fullWidth
            label="ISBN 13"
            variant="filled"
            margin="normal"
            name="isbn_13"
            onChange={handleChange}
          />
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Button
              onClick={() => navigate('/')}
              fullWidth
              variant="contained"
              sx={{ mt: 2, mr: 1 }}
              color="error"
            >
              Annuler
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ mt: 2, ml: 1 }}
              fullWidth
            >
              Créer
            </Button>
          </Box>
        </Box>
      </Container>
    </Base>
  )
}

export default CreationForm
