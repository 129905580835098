import * as React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import {
  CardActionArea,
  DialogTitle,
  Dialog,
  DialogActions,
  Button,
  DialogContent,
  Box,
  Divider,
  TextField,
} from '@mui/material'
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone'
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone'
import { useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import SuppressionValidator from './BookPopUp'
import StarIcon from '@mui/icons-material/Star'
import StarOutlineIcon from '@mui/icons-material/StarOutline'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'

export default function BookCard({ refresh, setRefresh, book }) {
  const [open, setOpen] = React.useState(false)
  const [fullWidth, setFullWidth] = React.useState(true)
  const [maxWidth, setMaxWidth] = React.useState('lg')
  const [cookies] = useCookies(['token'])
  const navigate = useNavigate()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const stars = []
  for (let i = 0; i < 5; i++) {
    i < book.note_5 ? stars.push(<StarIcon />) : stars.push(<StarOutlineIcon />)
  }

  return (
    <Card sx={{ maxWidth: 345, height: 300, margin: 'auto', marginBottom: 5 }}>
      <CardActionArea onClick={handleClickOpen}>
        <CardMedia
          component="img"
          height="140"
          image={
            book.image_url
              ? book.image_url
              : 'https://media.istockphoto.com/id/1409329028/fr/vectoriel/aucune-image-disponible-espace-r%C3%A9serv%C3%A9-ic%C3%B4ne-miniature-illustration-design.jpg?s=612x612&w=0&k=20&c=2G3XRVmxPifjkDYAzuAA-5QygJVWZ6LrFdgQHqOsGrw='
          }
          alt={book.title}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {book.title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {book.summary}
          </Typography>
        </CardContent>
      </CardActionArea>
      {/* Partie a extraire et remettre en composant */}
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          <Typography sx={{ fontSize: 30 }} align="center">
            {book.title}
          </Typography>
          <Typography sx={{ fontSize: 30 }} align="center">
            {stars}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}
          >
            <Box
              component="img"
              sx={{
                maxHeight: { xs: 250, sm: 350, lg: 450 },
                maxWidth: { xs: 167, sm: 233, lg: 350 },
                m: { xs: 'auto', sm: '0' },
              }}
              alt={book.title}
              src={
                book.image_url
                  ? book.image_url
                  : 'https://media.istockphoto.com/id/1409329028/fr/vectoriel/aucune-image-disponible-espace-r%C3%A9serv%C3%A9-ic%C3%B4ne-miniature-illustration-design.jpg?s=612x612&w=0&k=20&c=2G3XRVmxPifjkDYAzuAA-5QygJVWZ6LrFdgQHqOsGrw='
              }
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                m: 'auto',
                ml: 0,
                mt: 1,
                width: '100%',
                pl: 5,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row' },
                }}
              >
                {book.author ? (
                  <TextField
                    fullWidth
                    label="Auteur"
                    variant="filled"
                    margin="normal"
                    name="author"
                    InputProps={{
                      readOnly: true,
                    }}
                    value={book.author}
                    sx={{ mr: { xs: 0, sm: 1 } }}
                  />
                ) : null}
                {book.publisher ? (
                  <TextField
                    fullWidth
                    label="Editeur"
                    variant="filled"
                    margin="normal"
                    name="publisher"
                    InputProps={{
                      readOnly: true,
                    }}
                    value={book.publisher}
                    sx={{ ml: { xs: 0, sm: 1 } }}
                  />
                ) : null}
              </Box>

              {book.publish_date ? (
                <DatePicker
                  label="Date de publication"
                  defaultValue={dayjs(book.publish_date)}
                  readOnly
                  name="publish_date"
                  slotProps={{
                    textField: {
                      variant: 'filled',
                      sx: { mt: 2, mb: 1 },
                    },
                  }}
                />
              ) : null}
              {book.pages ? (
                <TextField
                  fullWidth
                  label="Pages"
                  variant="filled"
                  margin="normal"
                  name="pages"
                  InputProps={{
                    readOnly: true,
                  }}
                  type="number"
                  value={book.pages}
                />
              ) : null}
              <Divider />
              {book.summary ? (
                <TextField
                  fullWidth
                  label="Résumé"
                  variant="filled"
                  margin="normal"
                  name="summary"
                  InputProps={{
                    readOnly: true,
                  }}
                  value={book.summary}
                  multiline
                  rows={8}
                />
              ) : null}

              <Divider />
              {book.personnal_note ? (
                <TextField
                  fullWidth
                  label="Note personnelle"
                  variant="filled"
                  margin="normal"
                  name="personnal_note"
                  InputProps={{
                    readOnly: true,
                  }}
                  multiline
                  value={book.personnal_note}
                  rows={8}
                />
              ) : null}

              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row' },
                }}
              >
                {book.started_date ? (
                  <DatePicker
                    label="Date de début"
                    defaultValue={dayjs(book.start_date)}
                    readOnly
                    name="publish_date"
                    slotProps={{
                      textField: {
                        variant: 'filled',
                        sx: { mt: 2, mb: 1, mr: { xs: 0, sm: 1 } },
                        fullWidth,
                      },
                    }}
                  />
                ) : null}
                {book.ended_date ? (
                  <DatePicker
                    label="Date de fin"
                    defaultValue={dayjs(book.ended_date)}
                    readOnly
                    name="ended_date"
                    slotProps={{
                      textField: {
                        variant: 'filled',
                        sx: { mt: 2, mb: 1, ml: { xs: 0, sm: 1 } },
                        fullWidth,
                      },
                    }}
                  />
                ) : null}
              </Box>

              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                }}
              >
                {book.isbn_10 ? (
                  <TextField
                    fullWidth
                    label="ISBN 10"
                    variant="filled"
                    margin="normal"
                    name="isbn_10"
                    InputProps={{
                      readOnly: true,
                    }}
                    value={book.isbn_10}
                    sx={{ mr: { xs: 0, md: 1 } }}
                  />
                ) : null}
                {book.isbn_13 ? (
                  <TextField
                    fullWidth
                    label="ISBN 13"
                    variant="filled"
                    margin="normal"
                    name="isbn_13"
                    InputProps={{
                      readOnly: true,
                    }}
                    value={book.isbn_13}
                    sx={{ ml: { xs: 0, md: 1 } }}
                  />
                ) : null}
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              navigate('/edit-book', { state: { book: book } })
            }}
          >
            <EditTwoToneIcon />
          </Button>

          <SuppressionValidator
            book_id={book.id}
            cookies={cookies}
            setRefresh={setRefresh}
            setOpenParent={setOpen}
          />

          <Button onClick={handleClose}>
            <CancelTwoToneIcon />
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  )
}
