import React, { useState } from 'react'
import { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import Base from '../Components/Base'
import BookCard from '../Components/BookCard'
import { Grid, Button, Box } from '@mui/material'
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone'
import { useNavigate } from 'react-router-dom'

function MyBooks() {
  const [cookies] = useCookies(['token'])
  const [data, setData] = useState([])
  const [refresh, setRefresh] = useState(false)

  useEffect(() => {
    // loginChecker()
    get_book()
  }, [refresh])
  const navigate = useNavigate()
  const apiUrl = process.env.REACT_APP_API_URL

  // const loginChecker = () => {
  //   if (!cookies.token) {
  //     window.location.href = 'http://localhost:3000/login'
  //   }
  // }

  function get_book() {
    setRefresh(false)
    const xhr = new XMLHttpRequest()
    xhr.open('GET', `${apiUrl}/books/get/my-books`, true)
    xhr.setRequestHeader('Authorization', 'Bearer ' + cookies.token)
    xhr.onload = function () {
      if (xhr.status === 200) {
        setData(JSON.parse(xhr.responseText))
      } else {
        alert('Error: ' + xhr.responseText)
      }
    }
    xhr.send()
  }

  return (
    <div>
      <Base>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <h1>Mes livres</h1>
          <Button onClick={() => navigate('/add-book')}>
            <AddCircleTwoToneIcon />
          </Button>
        </Box>

        <Grid container spacing={1} columns={24}>
          {data.map((book, index) => {
            return (
              <Grid item xs={24} sm={12} md={8} lg={6} key={index}>
                <BookCard
                  title={book.title}
                  description={book.summary}
                  image_url={book.image_url}
                  book_id={book.id}
                  refresh={refresh}
                  setRefresh={setRefresh}
                  book={book}
                />
              </Grid>
            )
          })}
        </Grid>
      </Base>
    </div>
  )
}

export default MyBooks
