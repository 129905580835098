import {
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
} from '@mui/material'

function Bookmark({ text, open, icon, action }) {
  return (
    <ListItem
      key={text}
      disablePadding
      sx={{ display: 'block' }}
      onClick={action}
    >
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: open ? 'initial' : 'center',
          px: 2.5,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : 'auto',
            justifyContent: 'center',
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
      </ListItemButton>
    </ListItem>
  )
}

export default Bookmark
