import React, { useState, useEffect, useContext } from 'react'
import '../Style/LoginForm.css'
import { useCookies } from 'react-cookie'
import {
  Alert,
  TextField,
  Container,
  Box,
  Button,
  FormControl,
} from '@mui/material'
import { ApplicationContext } from '../Providers/ApplicationProvider'
import { useNavigate } from 'react-router-dom'

const LoginForm = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['token'])
  const [data, setData] = useState(null)
  const [error, setError] = useState(false)
  const navigate = useNavigate()
  const [formValues, setFormValues] = useState({
    username: '',
    password: '',
  })
  const apiUrl = process.env.REACT_APP_API_URL

  useEffect(() => {
    loginChecker()
  }, [cookies])

  const loginChecker = () => {
    if (cookies.token) {
      navigate('/')
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormValues({
      ...formValues,
      [name]: value,
    })
  }

  function login(event) {
    event.preventDefault()
    var username = formValues.username
    var password = formValues.password
    const now = new Date()
    const expirationDate = new Date(now.getTime() + 30 * 60 * 1000) // 3 heures * 60 minutes/heure * 60 secondes/minute * 1000 millisecondes/seconde
    const xhr = new XMLHttpRequest()
    xhr.open('POST', `${apiUrl}/token`)
    xhr.setRequestHeader(
      'Content-Type',
      'application/x-www-form-urlencoded',
      false
    )
    xhr.onload = function () {
      if (xhr.status === 200) {
        var data_temp = JSON.parse(xhr.responseText)
        setCookie('token', data_temp['access_token'], {
          expires: expirationDate,
          path: '/',
        })
        // alert(data_temp['access_token'])
      } else {
        setError(true)
      }
    }
    xhr.send(
      'grant_type=password&username=' + username + '&password=' + password
    )
  }

  return (
    <Container maxWidth="sm">
      <h1>Login</h1>
      <Box
        component="form"
        onSubmit={login}
        noValidate
        autoComplete="off"
        sx={{ mt: 4 }}
      >
        <TextField
          fullWidth
          label="Mail"
          variant="filled"
          margin="normal"
          name="username"
          onChange={handleChange}
        />
        <TextField
          fullWidth
          label="Mot de passe"
          variant="filled"
          margin="normal"
          name="password"
          type="password"
          onChange={handleChange}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
        >
          Soumettre
        </Button>
      </Box>
      <div className="wrapper">
        <div>
          {error ? (
            <Alert severity="error">
              Identifiant ou mot de passe incorrect.
            </Alert>
          ) : null}

          <div className="remember-forgot">
            <label>
              <input type="checkbox" />
              Remember me
            </label>
            Forgot password ?
          </div>

          <button>Login</button>

          <div className="register-link">
            <p>Don't have an account ? Register</p>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default LoginForm
